import secureLsService from '../secureLs.service';
import AppConst from '../../constants/app.constant';
import { supbaseSignUpNewUser } from '../supabase/supbaseSignUpNewUser';
import { supbaseSignInWithEmail } from '../supabase/supbaseSignInWithEmail';
import reportError from '../error-handler/reportError';

function removeCognitoLocalStorage() {
  for (let i = 0; i < localStorage.length; i += 1) {
    const key = localStorage.key(i);
    if (key.toLowerCase().includes('cognito')) {
      localStorage.removeItem(key);
    }
  }
}

const setMigrationDone = () => {
  localStorage.removeItem('bn_uc_copy');
  localStorage.removeItem('bn_uc');
};

const signinAfterMigration = async ({ Password, Username }) => {
  const signinResp = await supbaseSignInWithEmail({
    username: Username,
    userpassword: Password,
  });
  if (signinResp.error) {
    console.error('Error signin after migration completion', signinResp.error);
    reportError({
      message: 'Supabase migration Signin failed',
      code: 'SupabaseMigration',
      name: signinResp.error.message,
      signinResp,
    });
  }
};

const alreadyAwsLoggedInStrategy = async ({ Password, Username }) => {
  if (Username && Password) {
    const favourites = JSON.parse(
      localStorage.getItem(
        AppConst.localstorageConf.prefix + AppConst.localstorageConf.favoriteList,
      ),
    ) || [];
    const airport_search_history = JSON.parse(
      localStorage.getItem(
        AppConst.localstorageConf.prefix + AppConst.localstorageConf.airportKey,
      ),
    ) || [];
    const application_settings = JSON.parse(
      localStorage.getItem(
        AppConst.localstorageConf.prefix + AppConst.localstorageConf.applicationSettingKey,
      ),
    ) || null;

    const { data, error } = await supbaseSignUpNewUser(Username, Password, {
      data: {
        airport_search_history,
        favourites,
        application_settings,
        email: Username,
        first_name: '',
        last_name: '',
      },
    });
    if (error) {
      if (error?.message === 'User already registered') {
        // if user was already registerd then we can ignore that
        setMigrationDone();
        await signinAfterMigration({ Username, Password });
        console.error(error);
        return;
      }
      reportError({
        message: 'Supabase migration failed',
        code: 'SupabaseMigration',
        name: error.message,
        error,
      });
      console.error(error);
      return;
    }
    setMigrationDone();
    if (data.session) {
      await signinAfterMigration({ Username, Password });
    }
  }
};

const supabaseMigration = async () => {
  removeCognitoLocalStorage();
  // if migration done then dont do anything
  // already loggedin no need for migration
  if (localStorage.getItem('supabase_auth')) {
    return;
  }
  const user = secureLsService.getInfo(AppConst.localstorageConf.uc_copy);
  if (user) {
    await alreadyAwsLoggedInStrategy(user);
  }
};

export { supabaseMigration };
