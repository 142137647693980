import { routerReplaceInterface } from '../router.interface';
import { setUserDetails } from './setUserDetails';
import { setIsLoggedIn } from './setIsLoggedIn';
import { supbaseSignOut } from '../supabase/supbaseSignOut';

const doSignoutTask = async function () {
  const { error } = await supbaseSignOut();
  if (!error) {
    setIsLoggedIn(false);
    setUserDetails({});
    routerReplaceInterface({
      name: 'app.login',
    });
  }
};

export { doSignoutTask };
