import { supabase } from '../../supabaseClient';
import { supabaseGetEnvValue } from './supabaseGetEnvValue';

async function supabaseGetAllStripeProducts() {
  try {
    const requestPayload = supabaseGetEnvValue();
    const result = await supabase.rpc('bn_get_all_products', { request_payload: requestPayload });
    if (!result.error) {
      return result.data.map((item) => ({
        ...item,
        duration: item.recurring_interval.toUpperCase(),
      }));
    }
    return null;
  } catch (error) {
    console.error('Error getting profile data:', error.message);
  }
}

export { supabaseGetAllStripeProducts };
