import { supabaseGetUserProfile } from '../supabase/supabaseGetUserProfile';
import { userNeedSubscription } from '../user/userNeedSubscription';
import { userNeedToAcceptEula } from '../user/userNeedToAcceptEula';
import { setUserIdForMatomo } from '../user/setUserIdForMatomo';
import { getUserDetails } from '../user/getUserDetails';
import { setAppVersionUsed } from '../aws/user/setAttributes/setAppVersionUsed';
import { setAppInstallDate } from '../aws/user/setAttributes/setAppInstallDate';
import { navigateToLastStateOrDefault } from '../navigateToLastStateOrDefault';
import { initializeStripe } from '../stripe/initializeStripe';

const performSideEffect = (fromResumeCheck) => {
  const userDetails = getUserDetails();
  if (!fromResumeCheck) {
    navigateToLastStateOrDefault();
  }
  if (!userDetails.app_install_date) {
    setAppInstallDate();
  }
  setAppVersionUsed();
  userNeedToAcceptEula(userDetails);
  userNeedSubscription(userDetails);
  setUserIdForMatomo(userDetails);
};

const userAuthSuccess = async (fromResumeCheck) => {
  await supabaseGetUserProfile();
  await initializeStripe();
  performSideEffect(fromResumeCheck);
};

export { userAuthSuccess };
