import { supabase } from '../../supabaseClient';

async function supabaseGetCurrentUserSession() {
  try {
    // Get the current user session
    const {
      data: { session },
      error,
    } = await supabase.auth.refreshSession();
    if (error) {
      console.error('Error getting current user session:', error.message);
      return null;
    }

    if (session && session.user) {
      return session;
    }
    // console.log('No active session found.');
    return null;
  } catch (error) {
    console.error('Error getting current user session:', error.message);
    return null;
  }
}

export { supabaseGetCurrentUserSession };
