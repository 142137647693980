import { supabase } from '../../supabaseClient';

async function supabaseChangePassword({ userId, newPassword }) {
  const { error } = await supabase.auth.updateUser({
    id: userId,
    password: newPassword,
  });

  if (error) {
    console.error('Error changing password:', error.message);
    return error;
  }
  console.log('Password changed successfully!');
  return {};
}

export { supabaseChangePassword };
