import { supabase } from '../../supabaseClient';
import capacitorService from '../capacitor.service';

async function supabaseSignInWithOtp({ username }) {
  const redirectTo = capacitorService.isCapacitorMobileApp
    ? 'weatherspork://auth/success'
    : `${window.location.origin}/`;
  const { data, error } = await supabase.auth.signInWithOtp({
    email: username,
    options: {
      // set this to false if you do not want the user to be automatically signed up
      shouldCreateUser: true,
      emailRedirectTo: redirectTo,
    },
  });
  return { data, error };
}

export { supabaseSignInWithOtp };
