import { supabase } from '../../supabaseClient';
import { setUserDetails } from '../user/setUserDetails';
import { setIsLoggedIn } from '../user/setIsLoggedIn';
import { processUserAttributes } from '../aws/user/processUserAttributes';
import { supabaseGetEnvValue } from './supabaseGetEnvValue';

async function supabaseGetUserProfile() {
  try {
    const requestPayload = supabaseGetEnvValue();
    const result = await supabase.rpc('bn_get_user', { request_payload: requestPayload });
    if (!result.error && result.data.user_id) {
      const userData = await processUserAttributes(result.data);
      setUserDetails(userData);
      setIsLoggedIn(true);
      return userData;
    }
    return null;
  } catch (error) {
    console.error('Error getting profile data:', error.message);
  }
}

export { supabaseGetUserProfile };
