import { supabase } from '../../supabaseClient';

async function supabaseUpdateUser({ rpc, data }) {
  try {
    const result = await supabase.rpc(rpc, { request_payload: data });
    if (!result.error) {
      return true;
    }
    return false;
  } catch (error) {
    console.error('Error updating profile data:', rpc, error.message);
  }
}

export { supabaseUpdateUser };
